<template>
  <div class="loginForm">
    <login-form :redirect="redirectUrl" />
  </div>
</template>

<script>
const LoginForm = () => import("./components/LoginForm");
export default {
  name: "LoginIndex",
  components: {
    LoginForm
  },
  props: ["redirectUrl"]
};
</script>

<style lang="scss" scoped>
.loginForm {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
